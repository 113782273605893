$background :#151519 !important;
$bg-sec:#1c1c1f !important;
$green: #99B898;

$outline:#333;;
$red: #ff7b72;
$secondary: #1c1c1f;

$danger:#ff7b72 !important;

$text-primary: #eee !important;
$text-secondary: #ccc !important;

$primary: #0069ff !important;
$bg-primary: #151519;
$bg-secondary: #1c1c1f;



.container{
  min-height: 100vh;
  position: relative;
}



body{
  background-color: $background;
  color: $text-primary;
}

.bg-sec{
  background-color: $bg-secondary !important;
}
body {
  --bg-primary: $background;
  --bg-secondary: #1c1c1f;
  --border-color: #333;
  --text-secondary: #ccc !important;
  --text-red: #ff7b72;
  --text-lightblue: #79c0ff;
  --text-primary: #eee;
  --overlay: rgba(0,0,0,0.5);
  --bad-luck-color: #bbb;
  --code-comment: #8b949e;
  --code-keyword: #ff7b72;
  --code-key: #7ee787;
  --code-constant: #79c0ff;
  --code-string: #a5d6ff;
  --code-number: #79c0ff;
}
.text-blue{
  color:#0069ff;
}
.text-lightblue{
  color: #79c0ff;
}

.textsecondary{
 color: #ccc !important;
}

a {
  color: #eee !important;
  text-decoration: none !important;
}

.outsidelink {
  color: #79c0ff !important;
 
}

.center{
  justify-content: center !important;
  align-items: center!important;
}

.App {
  background-color: $background;
  color: $text-primary;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}


.lead {
  font-size: 20px !important;
  line-height: 150% !important;
  font-weight: 300;
}

.textwrap{
  word-wrap: break-word;
  display: block !important;
}


.card-gif {
  background-color: $bg-sec;
  width: 100%;
  
  border: 1px solid $outline !important;
  border-radius: 8px !important;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  cursor: pointer;
  transition: 0.4s;

  .gif-img{
    width: 100%;
    border-radius: 8px !important;
  }
  
}

.card-gif:hover{
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0,0,0,0.25), 
    -5px -5px 30px 15px rgba(0,0,0,0.22); 
}

.todobutton{
  height: 48px;
}

.block{
  display: block !important;
}

.list-group-item {
  position: relative;
  display: block;
  text-decoration: none;
  background-color: $background;
  color: #eee  !important;
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  font-size: 16px;
  display: block !important;
  --bs-list-group-border-color: #202A30;
}


.add {
  transition: all 0.1s ease 0s;
  height: 100%;
  justify-content: center;
  background-color: $primary;
  color: $text-primary;
  align-items: center;
  display: flex;
  border-radius: 0px 5px 5px 0px !important;
  border: 0px none;
  cursor: pointer;
  height: 48px;
}

.form {
  height: 48px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color) !important;
  border-radius: 5px 0px 0px 5px;
  padding: 0px 1rem;
  font-size: 1rem;
  outline: none;
  width: 100%;
  font-weight: 400;
  display: block;
  color: var(--text-primary);
  transition: all 0.1s ease 0s;
}
